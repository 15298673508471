<template>
    <section>
        <div class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
            <p class="text-general-red f-30 f-600 mt-3 text-center">
                {{ titulo }}
            </p>
            <p class="text-general f-15 mt-1 f-500 text-center">
                {{ text }}
            </p>
            <p v-show="[1,2].includes(tipo)" class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: route})">
                Ir a la aplicación de {{ name }}
            </p>
        </div>
    </section>
</template>

<script>
const hashMessages = {
    1: { text: 'Estás intentando ingresar como un administrador.', name: 'administrador', route: 'login.form.admin' },
    2: { text: 'Estás intentando ingresar como un cliente.', name: 'cliente', route: 'login.form.cliente' },
    3: { text: 'Ya hay un usuario registrado con ese número' },
    4: { text: 'No existe el usuario' }
}

export default {
    props: {
        tipo: {
            type: Number,
            default: 1
        },
        titulo: {
            type:String,
            default: 'Espera'
        }
    },
    computed: {
        text(){
            return hashMessages[this.tipo].text
        },
        name(){
            return hashMessages[this.tipo].name
        },
        route(){
            return hashMessages[this.tipo].route
        },
    }
}
</script>

<style lang="scss" scoped>
.text-green{
    color: #28D07B !important;
}
.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 350px;
    height: 138px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}
</style>